import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { tatooportfolio, paintingportfolio, soundportfolio, movementportfolio, meta } from "../../content_option";
// import ImagePreview from "../../components/ImagePreview";

export const Portfolio = () => {
  return (
    <HelmetProvider>
      {/* <ImagePreview /> */}
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Portfolio | {meta.title} </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4"> Portfolio </h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <h4 className="title mb-4"> Handpoke <a href="https://forms.gle/qbZNpgvEVWBBrshw5" target="_blank" >{'(🔗Form)'}</a> </h4>{" "}
        <div className="mb-5 po_items_ho">
          {tatooportfolio.map((data, i) => {
            return (
              <div key={i} className="po_item">

                <img src={data.img} alt="" />
                <div className="content">
                  <p className="content-desc">{data.desctiption}</p>
                  {/* <a href={data.link}>view project</a> */}
                </div>
              </div>
            );
          })}
        </div>
        <h4 className="title mb-4"> Painting </h4>{" "}
        <div className="mb-5 po_items_ho">
          {paintingportfolio.map((data, i) => {
            return (
              <div key={i} className="po_item">
                <img src={data.img} alt="" />
                <div className="content">
                  <p className="content-desc">{data.desctiption}</p>
                  {/* <a href={data.link}>view project</a> */}
                </div>
              </div>
            );
          })}
        </div>
        <h4 className="title mb-4"> Sound Bath <a href="https://forms.gle/P2B8v2B52aY9gt1z8" target="_blank" >{'(🔗Form)'}</a>  </h4>{" "}
        <div className="mb-5 po_items_ho">
          {soundportfolio.map((data, i) => {
            return (
              <div key={i} className="po_item">
                <img src={data.img} alt="" />
                <div className="content">
                  <p className="content-desc">{data.desctiption}</p>
                  {/* <a href={data.link}>view project</a> */}
                </div>
              </div>
            );
          })}
        </div>
        {movementportfolio.length > 0 &&
          <>
            <h4 className="title mb-4"> Movement </h4>{" "}
            <div className="mb-5 po_items_ho">
              {movementportfolio.map((data, i) => {
                return (
                  <div key={i} className="po_item">
                    <img src={data.img} alt="" />
                    <div className="content">
                      <p className="content-desc">{data.desctiption}</p>
                      {/* <a href={data.link}>view project</a> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        }

      </Container>
    </HelmetProvider>
  );
};
