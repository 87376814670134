const logotext = "Malini";

const meta = {
  title: "Malini Ganeshan",
  description: "Artist, Dancer, Handpoke Tatoo Artist, Painter, Handpan/ Hang drum player and soundbath healer"
};

const introdata = {
  title: "",
  animated: {
    first: "Movement",
    second: "Sound Bath",
    third: "Handpoke",
    fourth: "Painting"
  },
  description: "The space of expression and creativity",
};

const dataabout = {
  title: "I",
  aboutme:
    "In the realm of creative enigmas, a mystic weaver of the senses emerges, entwining the tapestry of existence through eclectic artistry. Dancing in the shadows of rhythm, adorning skin with etchings of whispered dreams, and orchestrating celestial melodies from resonant steel, this multifaceted virtuoso conjures vibrant landscapes upon the canvas of the cosmos. Enter the labyrinthine world of the quintessential chimera, and let your soul be enraptured by a tantalizing fusion of handpoke ink, handpan reverberations, fluid motion, and painted symphonies."
};

const worktimeline = [
  {
    jobtitle: "Handpoke",
    // where: "YAdfi",
    date: "Sunday to Thrusday afternoon"
  },
  {
    jobtitle: "Sound Bath",
    // where: "Jamalya",
    date: "Sunday to Thrusday 6:00 am to 6:00 pm"
  },
  {
    jobtitle: "Movement",
    // where: "ALquds",
    date: "Sunday, Tuesday, Thrusday at 9:00 am"
  }
];

const skills = [
  {
    name: "Python",
    value: 90
  },
  {
    name: "Djano",
    value: 85
  },
  {
    name: "Javascript",
    value: 80
  },
  {
    name: "React",
    value: 60
  },
  {
    name: "Jquery",
    value: 85
  }
];

const services = [
  {
    title: "Handpoke",
    formLink: "https://forms.gle/qbZNpgvEVWBBrshw5",
    description:
      "What is hand poke ?Handpoke (also known as stick and poke) is a type of tattooing technique where a needle is inserted into the skin by hand, rather than using a tattoo machine. The needle is held by hand and repeatedly poked into the skin to create the desired design. Handpoke tattoos typically have a different aesthetic than machine tattoos, with a softer, more organic look. Being trained by George D’Souza for hand poke at Ahimsa Tattoo - The first India’s Vegan Tattoo and Piercing Studio, with his guidance I am certified to poke."
    , take: `What is Malini’s take on handpoke ? 
It’s a primitive arts.
Where things are taken to basic, I am not opposed to machine. At the moment and how I feel currently as I found a new expression of myself I love to be connected to my muse.
It has an element of humbleness.
It takes more energy. The way I view it,it’s a healing art. Just like acupuncture so much of it’s I believe is in the intention that we set. And what you’re releasing and what you’re coming into. Because tattoos will always mark a moment in time.`,
    image: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/sample.jpg?alt=media&token=032c7e80-7882-4a99-b167-7c890a6a63a6",
    position: "right"

  },
  {
    title: "Sound Bath",
    formLink: "https://forms.gle/P2B8v2B52aY9gt1z8",
    description: `The instrument you hear in my session sound bath, specifically when referring to our practice, is the handpan. This instrument produces a warm, melodic, and soothing sound which is the result of its unique construction—two hemispherical shells of steel fused together, creating a resonant hollow space inside.
    The concept of a sound bath is rooted in the idea of immersion in sound waves to promote relaxation and healing. The term "bath" metaphorically suggests that participants are enveloped in the sounds, similar to being submerged in water. This immersive experience is designed to engage the listener's attention in the moment, using the soothing tones of the handpan to induce a state of deep relaxation and meditation.`,
    take: `The handpan is particularly effective because its ethereal tones and overtones can resemble the natural rhythms and sounds that are familiar to us from a very young age, such as our mother's heartbeat. This fundamental rhythm is often associated with safety and comfort. If someone's early life experiences were marked by stress or unrest, it might disrupt their ability to find tranquility. Sound baths aim to reintroduce the body and mind to a restful state, which can be especially beneficial for those who have lost touch with what it means to be calm and still. Through the gentle, rhythmic sounds of the handpan, participants can be guided away from restlessness and anxiety, moving towards a more centered and peaceful state of being. Regular sessions with the handpan can help individuals find solace and a sense of home within themselves, as they reflect and calm their nervous systems.`,
    image: "",
    three: "hand_pan",
    textCol: "10",
    position: "left"
  },
  {
    title: "Movement",
    description: `What is dance movement? 
Dance movement therapy (DMT) is a form of psychotherapy that uses movement and dance 4 as a means of emotional, cognitive, and physical healing. It is based on the idea that the body and mind are interconnected and that movement can be a powerful tool for promoting self-awareness, expression, and healing.`,
    take: `With over 19 years of experience as a trained professional Bharatanatyam dancer and explorations in various dance flow forms, I have honed a deep connection to music and movement. My journey has been enriched by mentorships with renowned artists from around the globe, allowing me to express the rhythms of sound and silence through my movements.

    As a certified Dance Movement Therapy (DMT) practitioner from the Tata Institute of Social Sciences (TISS) and Kolkata Sanved, I delve into the profound interplay between body, emotions, and expression. In our developing society, where mental health increasingly gains attention, introducing movement wellness is both timely and essential. Dance Movement Therapy is not just a method—it's a transformative process towards holistic well-being.`,
    image: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/movement_portfolio%2F_1.png?alt=media&token=a99f2303-772c-4335-a76e-67eaa5a313ff",
    position: "right"
  }
];

const tatooportfolio = [
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/_3.jpg?alt=media&token=6ed94b11-f7ce-4173-915d-b2aa328ef039",
    desctiption: "A flow - leaves flowing and growing out, branches making way to evolve- just how we extend ourselves to survive.",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/_2.jpg?alt=media&token=5cbe79f1-dde8-4283-9702-07ccfce5a262",
    desctiption: "A Point/Start to end.",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/_5.jpg?alt=media&token=b652fbdd-3446-4242-8aa2-6f56bb3f64d3",
    desctiption: "A dancer - a free flow artist Movements flowing around her thru her.",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/_1.jpg?alt=media&token=bfcf5fb7-f73a-4cf0-9b8e-811c2e3c9d04",
    desctiption: "Equality for all nature beings.",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/_7.jpg?alt=media&token=e9c087ef-ce3f-455a-80c9-bb925c800fef",
    desctiption: "Addiction is a monster; it lives inside, and feeds off of you, takes from you, controls you, and destroys you. A line art to show a messy Mind space.",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/_4.jpg?alt=media&token=00bace8d-f73d-44ae-801a-10c3d34d6876",
    desctiption: "The two eyes (baymaxx), the dots, the two lovers connecting forever.",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/_6.jpg?alt=media&token=a6c45b04-4e1e-4bf5-8659-f6d8295294ac",
    desctiption: "Two moles to indicate two individuals and their friendship of togetherness.",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_8.jpeg?alt=media&token=34d94b6c-f4af-4821-8a5e-9c902a18409e",
    desctiption: "[Free hand] A near to heart and for a dear one, this tattoo is a twin flame with a touch of yin and Yang. Balance, Delicate and Strength.",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_9.jpg?alt=media&token=3febe9d0-1d35-45d0-a5a8-29b3646e4e0b",
    desctiption: "The one day cycle and 28 day cycle, does both really bring out the balance ?",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_10.jpg?alt=media&token=40c2ea9c-b572-4bfc-a1fa-10df7660bffa",
    desctiption: "Lighting from the sky or rooting from the ground",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_11.jpg?alt=media&token=3a642623-0b2d-48cd-9074-3d838c1bf2ba&_gl=1*yvd55j*_ga*MjI1NDc1NTEuMTY5Njc2ODc4OQ..*_ga_CW55HF8NVT*MTY5Njc4NjMzOS4yLjEuMTY5Njc4NjQyNi42MC4wLjA.",
    desctiption: "I HAVE BECOME ALIGNED",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_12.jpg?alt=media&token=e5e363d6-5d61-409b-84c6-cea74d0203e4&_gl=1*1877ldl*_ga*MjI1NDc1NTEuMTY5Njc2ODc4OQ..*_ga_CW55HF8NVT*MTY5Njc4NjMzOS4yLjEuMTY5Njc4NjQ4OC42MC4wLjA.",
    desctiption: "MA MEDUSA",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_13.jpg?alt=media&token=06603954-f758-432c-9aa7-e13f60f6186a&_gl=1*1l4yo3u*_ga*MjI1NDc1NTEuMTY5Njc2ODc4OQ..*_ga_CW55HF8NVT*MTY5Njc4NjMzOS4yLjEuMTY5Njc4NjUxMi4zNi4wLjA.",
    desctiption: "KUNDALINI",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_14.jpg?alt=media&token=08db4d4d-6240-4531-8c69-77eecaad0399&_gl=1*1lyorfd*_ga*MjI1NDc1NTEuMTY5Njc2ODc4OQ..*_ga_CW55HF8NVT*MTY5Njc4NjMzOS4yLjEuMTY5Njc4NjUyNi4yMi4wLjA.",
    desctiption: "FLOW OR GROW",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_15.jpg?alt=media&token=23146ad4-a28c-453e-acd6-dce1dc598d8e&_gl=1*1ekq29a*_ga*MjI1NDc1NTEuMTY5Njc2ODc4OQ..*_ga_CW55HF8NVT*MTY5Njc4NjMzOS4yLjEuMTY5Njc4NjUzOS45LjAuMA..",
    desctiption: "THE HEART IS FILLING",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_16.jpg?alt=media&token=27d5fd9f-8c6b-494f-b6cb-dd55b0f03395&_gl=1*1o7zkfg*_ga*MjI1NDc1NTEuMTY5Njc2ODc4OQ..*_ga_CW55HF8NVT*MTY5Njc4NjMzOS4yLjEuMTY5Njc4NjU1My42MC4wLjA.",
    desctiption: "Bad b8h",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_17.jpg?alt=media&token=420bb9c4-18ad-4fc9-9269-7dd11f964fb5&_gl=1*blx3c0*_ga*MjI1NDc1NTEuMTY5Njc2ODc4OQ..*_ga_CW55HF8NVT*MTY5Njc4NjMzOS4yLjEuMTY5Njc4NjU2OC40NS4wLjA.",
    desctiption: "SHARANYA",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_18.jpg?alt=media&token=88adfca7-19dc-4a86-85d0-27ac47f664bd&_gl=1*13mbvy2*_ga*ODgyNzg4Mzg4LjE2OTgwODAzMTg.*_ga_CW55HF8NVT*MTY5ODA4MDMxOS4xLjEuMTY5ODA4MTM3MC42MC4wLjA.",
    desctiption: "The eyes will take you.",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_19.jpg?alt=media&token=b46137d1-5f97-410f-ae36-48d95539339b&_gl=1*8ggf5*_ga*ODgyNzg4Mzg4LjE2OTgwODAzMTg.*_ga_CW55HF8NVT*MTY5ODA4MDMxOS4xLjEuMTY5ODA4MTM5MC40MC4wLjA.",
    desctiption: "The nature eyes",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_20.jpg?alt=media&token=92851a8c-1e43-4721-b95d-771ed2cd2878&_gl=1*z286n0*_ga*ODgyNzg4Mzg4LjE2OTgwODAzMTg.*_ga_CW55HF8NVT*MTY5ODA4MDMxOS4xLjEuMTY5ODA4MTQwNi4yNC4wLjA.",
    desctiption: "Mole cule",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_21.jpg?alt=media&token=119a6cfb-6ab0-46c5-8512-375ad58a06d5&_gl=1*tn9red*_ga*ODgyNzg4Mzg4LjE2OTgwODAzMTg.*_ga_CW55HF8NVT*MTY5ODA4MDMxOS4xLjEuMTY5ODA4MTQyMC4xMC4wLjA.",
    desctiption: "Authenticity and Dance",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_22.jpg?alt=media&token=9a4b8a7b-8075-4a0f-99dd-691c20b617c8&_gl=1*1wm02b4*_ga*ODgyNzg4Mzg4LjE2OTgwODAzMTg.*_ga_CW55HF8NVT*MTY5ODA4MDMxOS4xLjEuMTY5ODA4MTQ0OC42MC4wLjA.",
    desctiption: "Geometric change ",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_23.jpg?alt=media&token=2db606ee-a454-45ad-a838-3eb377098733&_gl=1*1bxum0h*_ga*ODgyNzg4Mzg4LjE2OTgwODAzMTg.*_ga_CW55HF8NVT*MTY5ODA4MDMxOS4xLjEuMTY5ODA4MTQ2NC40NC4wLjA.",
    desctiption: "Life's a circle.",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_24.jpg?alt=media&token=fd73167d-e354-4fb5-b66f-ef35f52530e6&_gl=1*1g1zk3z*_ga*ODgyNzg4Mzg4LjE2OTgwODAzMTg.*_ga_CW55HF8NVT*MTY5ODA4MDMxOS4xLjEuMTY5ODA4MTQ3Ni4zMi4wLjA.",
    desctiption: "Dissociated",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_25.jpg?alt=media&token=0961aab6-4e13-4ea1-a642-9788143e3c44",
    desctiption: "Flow freely",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_26.jpg?alt=media&token=9b62339c-ebac-4dba-a91a-689d35b73a78",
    desctiption: "Archangel",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_27.jpg?alt=media&token=5ce3ac2a-7b3d-480f-b710-17526c49c329",
    desctiption: "Moon child",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_28.GIF?alt=media&token=ad695af8-ecef-448f-953b-cf82dfb744a9",
    desctiption: "Phoenix",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_29.jpg?alt=media&token=0ff2cb94-de79-4cc7-89c8-b306e89b30a4",
    desctiption: "I CAN FLY",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_30.PNG?alt=media&token=e68a4d6f-6ee2-4faf-b6d6-942b92a42d0f",
    desctiption: "I am rising",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_31.PNG?alt=media&token=806b770e-dc94-4a51-9971-85c06c025aa6",
    desctiption: "Circle of life",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_32.jpg?alt=media&token=285cc3c6-b008-4d28-8630-53c5f488e27d",
    desctiption: "5 Element of nature",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_33.jpg?alt=media&token=3fc8d447-78fc-4de1-9418-591f731412df",
    desctiption: "Play life",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_34.jpg?alt=media&token=53c794e2-30e5-4ba2-a7f2-6da53794280d",
    desctiption: "Enlightenment",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_35.jpg?alt=media&token=2523510d-cc47-46cf-b2a7-cdf3991dc121",
    desctiption: "Are we even together?",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_36.jpg?alt=media&token=52354f16-d7c4-4a46-9aaa-d7deba4f9523",
    desctiption: "Ishq vishk",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_37.jpg?alt=media&token=0c63c838-e212-4cf9-9848-1b3b8c9ff3cd",
    desctiption: "Indus",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_38.jpg?alt=media&token=93042c84-ee98-40cb-adb5-1c6c9edfa645",
    desctiption: "I am Right",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_39.jpg?alt=media&token=286c0980-a625-4feb-a64e-84d8bcd44489",
    desctiption: "Left is right",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/tatoo_portfolio%2F_40.jpg?alt=media&token=69dc2b58-d4e0-40dd-8da8-6f870ac2618f",
    desctiption: "Hear me out",
    link: "#"
  }
]

const paintingportfolio = [
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/painting_portfolio%2F_1.jpeg?alt=media&token=cbbb9228-72ac-49fc-ad17-2e8c47753f3f",
    desctiption: "[IDE - A] We are governed by the ideologies of men.",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/painting_portfolio%2F_2.jpeg?alt=media&token=7051ff21-d2fe-468b-b999-a026ac9f233a",
    desctiption: "[FREE -ZE] I wanna feel secure enough to be truly free,I have always been fighting for what’s mine, running from things,just surviving,I wanna feel safe enough that I can just be free in the my own body of home and the surroundings.",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/painting_portfolio%2F_3.jpeg?alt=media&token=34593130-0e08-4afc-bf3f-50a1201fda60",
    desctiption: "[CL-AIM] Your mind just doing what’s being programmed. Professional Claim, why do you wanna claim? Why do we want to claim things ? Synchronicity?Just how you select your clothes I select what to think on, Universe is user friendly it’s responsive and interactive is what I have noticed. One can feel empower by their sadness.Joy isn’t only the process.Beauty is in making mistakes.",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/painting_portfolio%2F_4.jpeg?alt=media&token=6e2bfdeb-9a26-4e42-898b-dd8032ebeddf",
    desctiption: "[Shi-ft] The sense of something known but so unknon.",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/painting_portfolio%2F_5.jpeg?alt=media&token=f34bde5c-fe3f-451e-ba10-fb57dcdf7cf7",
    desctiption: "[LI - VE] The state of our digestion often reflects the state of our minds because the gut is like a second brain, it's where our intuition lives.",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/painting_portfolio%2F_6.jpg?alt=media&token=c36c8cfa-6ff7-45c5-91a0-f584ea0c14d3",
    desctiption: "NIKITA",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/painting_portfolio%2F_7.jpg?alt=media&token=8a7b2f13-2b04-4ed4-9fc1-39bf69558539",
    desctiption: "BLU- HUES",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/painting_portfolio%2F_8.jpg?alt=media&token=7f070ff5-2af5-4896-ae76-bb2afe812c5c",
    desctiption: "D - ark - ness - before chaos",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/painting_portfolio%2F_9.jpg?alt=media&token=e3afa980-1e9d-4574-9b7d-3feacdce1bec",
    desctiption: "Red L-ight",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/painting_portfolio%2F_10.jpg?alt=media&token=c4052468-bb90-4b4d-9f1b-48e17db43695",
    desctiption: "I don't k - now",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/painting_portfolio%2F_11.jpg?alt=media&token=03032e9d-24a1-45d1-b27a-c60d24937da6",
    desctiption: "BLEED~ING",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/painting_portfolio%2F_12.jpg?alt=media&token=db3873af-f2e0-4eb4-b7ee-e14520e5630f",
    desctiption: "If I could I would",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/painting_portfolio%2F_13.jpg?alt=media&token=f3840e07-554f-4463-80d1-a6a28cd49413",
    desctiption: "The second part",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/painting_portfolio%2F_14.jpg?alt=media&token=858d0af7-6c35-4f92-8855-1487cbf3f79d",
    desctiption: "Bon Appétite",
    link: "#"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/painting_portfolio%2F_15.jpg?alt=media&token=790ae651-02a1-48e4-8bc2-eedbfdfc9b59",
    desctiption: "Melting for Y -OU",
    link: "#"
  }
];

const soundportfolio = [
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/sound_portfolio%2F_1.jpg?alt=media&token=9d653154-396d-4955-83d7-709552f0bfb0",
    desctiption: "A Sound Bath is a deeply-immersive, full-body listening experience that intentionally uses sound to invite gentle yet powerful therapeutic and restorative processes to nurture body,mind and soul.",
    link: "#"
  }
]

const movementportfolio = [
  {
    img: "https://firebasestorage.googleapis.com/v0/b/malini-live.appspot.com/o/movement_portfolio%2F_1.png?alt=media&token=a99f2303-772c-4335-a76e-67eaa5a313ff",
    desctiption: "MOVEMENT - we all have been young,but if we’re lucky enough, we will all get old.   Know your body and your rhythm to keep going in life",
    link: "#"
  }
]

const contactConfig = {
  YOUR_EMAIL: "connectmalini.life@gmail.com",
  YOUR_FONE: "(+91) 7900108179",
  description:
    `Settling back into our intentions after exploring thru here, connect to mark a moment of stillness. 
For workshops, booking hand poke, or to buy paintings or commissions project, connect with me why do you feel inclined to me and what service do you want me to provide? `,

  sub_desc: "In gratitude of your being! M̴̨̭̰͈̠͍͛̽̿̄͑ā̵̢͖̤̝̦̱̇͋͐͝l̸̡̹̘̯̮̜͋̇͛͆̕i̵̧̟̣̹̭̠͋͊̄̽͘n̷̢̻͇̥͔̟̈́̅͋̚͝i̴̡̯͈̠̮̲͋̽͑͗͘",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "service_u8chebz",
  YOUR_TEMPLATE_ID: "template_47q8koa",
  YOUR_USER_ID: "ko0yTIzcYKAsfvRls"
};

const socialprofils = {
  instagram: "https://instagram.com/m6lini?igshid=NzZlODBkYWE4Ng%3D%3D&utm_source=qr",
  facebook: "",
  twitter: "https://twitter.com",
  youtube: ""
};

export {
  meta,
  dataabout,
  paintingportfolio,
  tatooportfolio,
  soundportfolio,
  movementportfolio,
  worktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext
};
