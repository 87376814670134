import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typewriter from "typewriter-effect";
import { introdata, meta } from "../../content_option";
import { Link } from "react-router-dom";
import dance from "../../assets/design/dance.png";
// import bg_image_2 from "../../assets/images/bg_image_2.jpg";

import WaterWave from "../../components/WaterEffect/index.tsx";

export const Home = () => {
  return (
    <HelmetProvider>
      <section id="home" className="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title> {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <div className="intro_sec d-block d-lg-flex align-items-center ">
          <div className="">
            <WaterWave id="waterwave" imageUrl={dance} style={{ width: "100vw", height: "100vh", backgroundSize: "cover" }}>
              {({ pause, play }) => (
                <div
                  className="container"
                  onClick={() => {
                    console.log("play = ");
                    play();
                  }}></div>
              )}
            </WaterWave>
            {/* <SnakeBugs chainCount={50} entityCount={20} /> */}
          </div>
          <div className="main-text-parent">
            <div className="main-text-bg">
            </div>

            <div className="main-text-content">
              <h2 className="mb-1x">{introdata.title}</h2>
              <h1 className="fluidz-48 mb-1x">
                <Typewriter
                  className="typewriter"
                  options={{
                    wrapperClassName: "typewriter",
                    strings: [introdata.animated.first, introdata.animated.second, introdata.animated.third, introdata.animated.fourth],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 10
                  }}
                />
              </h1>
              <p className="main-desc">{introdata.description}</p>
              <div className="intro_btn-action pb-5">
                <Link to="/portfolio" className="text_2">
                  <div id="button_p" className="ac_btn btn ">
                    My Portfolio
                    <div className="ring one"></div>
                    <div className="ring two"></div>
                    <div className="ring three"></div>
                  </div>
                </Link>
                <Link to="/contact">
                  <div id="button_h" className="ac_btn btn">
                    Contact Me
                    <div className="ring one"></div>
                    <div className="ring two"></div>
                    <div className="ring three"></div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};
