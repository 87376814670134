import React, { useEffect, useRef } from "react";
import "./style.css";
import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

export const ThreeSample = ({
  select_model = 'devils_dance',
  isFixed,
  render_width = window.innerWidth,
  render_height = window.innerHeight,
  custom_style = {},
  rotation = {
    x: 2,
  },
  position = {
  }
  ,
}) => {
  const threeParentRef = useRef();

  useEffect(() => {

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);


    const loader = new GLTFLoader();
    let model;

    loader.load(
      `./${select_model}/scene.gltf`,
      (gltf) => {
        model = gltf.scene
        model.rotation.x += rotation.x;

        if (position.x) model.position.x += position.x;
        if (position.y) model.position.y += position.y;

        scene.add(model);
      }, (xhr) => {
        //While it is loading, log the progress
        console.log((xhr.loaded / xhr.total * 100) + '% loaded');
      }, (error) => {
        console.log("gltf loader error = ", error)
      });


    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(render_width, render_height);

    if (threeParentRef.current) {
      threeParentRef.current.appendChild(renderer.domElement);
    }


    camera.position.z = 3;

    //Add lights to the scene, so we can actually see the 3D model
    const topLight = new THREE.DirectionalLight(0xffffff, 1); // (color, intensity)
    topLight.position.set(500, 500, 500) //top-left-ish
    topLight.castShadow = true;
    scene.add(topLight);

    const ambientLight = new THREE.AmbientLight(0x333333, 5);
    scene.add(ambientLight);

    // let controls = new OrbitControls(camera, renderer.domElement);

    const animate = () => {
      requestAnimationFrame(animate);

      // Auto-rotate the model
      if (model) {
        model.rotation.y += 0.01;
        // model.rotation.x += 0.01;
      }

      renderer.render(scene, camera);
    }
    animate();
    return () => {
      if (threeParentRef.current) {
        threeParentRef.current.removeChild(renderer.domElement);
      }
    };
  }, []);


  return (<div ref={threeParentRef} id="three-parent" style={{ ...custom_style, position: `${isFixed ? 'fixed' : 'absolute'}` }}></div>)
}
