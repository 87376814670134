import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import {
  dataabout,
  meta,
  worktimeline,
  skills,
  services,
} from "../../content_option";
import { ThreeSample } from "../ThreeSample";

export const About = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const getImg = (data) => {
    return (
      <>
        {data.image && <Col lang="5">
          <img src={data.image} alt="" className="service_img" />
        </Col>}{
          data.three && <Col lang="2" style={{ position: "relative" }}>
            <ThreeSample select_model={data.three}
              custom_style={{
                top: "0%",
                left: "0%",
                width: "100%"
              }}
              rotation={{
                x: -4.5
              }}
              position={{
                x: isMobile ? -1.2 : -3.8,
                y: isMobile ? 1.2 : 1.2
              }}
            />
          </Col>
        }
      </>
    )
  }

  const getDesc = (data) => {
    return (
      <>
        <Col lg={`${data.three ? data.textCol : data.image ? "7" : "12"}`}>
          <div className="service_">
            <h5 className="service__title">{data.title} {data.formLink && <a href={data.formLink} target="_blank" >{'(🔗Form)'}</a>} </h5>
            <p className="service_desc">{data.description}</p>
            {data.take && <p className="service_take">{data.take}</p>}
          </div>
        </Col>
      </>
    )
  }

  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> About | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>

        <Row className="mb-5 mt-3">
          <Col lg="12">
            <h1 className="display-4 mb-4">About me</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp">
          {/* <Col lg="5">
            <h3 className="color_sec py-4">{dataabout.title}</h3>
          </Col> */}
          <Col lg="12" className="d-flex align-items-center">
            <div>
              <p>{dataabout.aboutme}</p>
            </div>
          </Col>
        </Row>
        {/* <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">Skills</h3>
          </Col>
          <Col lg="7">
            {skills.map((data, i) => {
              return (
                <div key={i}>
                  <h3 className="progress-title">{data.name}</h3>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{
                        width: `${data.value}%`,
                      }}
                    >
                      <div className="progress-value">{data.value}%</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Col>
        </Row> */}
        {services.map((data, i) => {
          return (
            <Row className="sec_sp" key={i}>
              {data.position === "left" || isMobile ? getImg(data) : getDesc(data)}
              {data.position === "right" && !isMobile ? getImg(data) : getDesc(data)}
            </Row>
          );
        })}
        <Row id="C4" className="sec_sp">
          <Col lg="4">
            <h3 className="color_sec py-4">Work Timing</h3>
          </Col>
          <Col lg="8" className="mb-5">
            <table className="table caption-top">
              <tbody>
                {worktimeline.map((data, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">{data.jobtitle}</th>
                      <td>{data.where}</td>
                      <td>{data.date}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    </HelmetProvider>
  );
};
