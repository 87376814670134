import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, useLocation, withRouter } from "react-router-dom";
import AppRoutes from "./routes";
import Headermain from "../header";
import AnimatedCursor from "react-animated-cursor";
import "./App.css";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import handpan from "../assets/music/handpan.mp3";

function _ScrollToTop(props) {
  const { pathname } = useLocation();

  useEffect(() => {
    const audio = new Audio(handpan);
    audio.oncanplaythrough = () => {
      document.body.addEventListener("click", () => {
        console.log("click");
        audio.play();
      });
      audio.play();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

const firebaseConfig = {
  apiKey: "AIzaSyBx239urdppoFDXqVbzvqtvRme502qyeZE",
  authDomain: "malini-live.firebaseapp.com",
  projectId: "malini-live",
  storageBucket: "malini-live.appspot.com",
  messagingSenderId: "957531978478",
  appId: "1:957531978478:web:6e211de8d4f4bdd2e791ff",
  measurementId: "G-GE3LNGCER7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      {/* <div className="cursor__dot">
        <AnimatedCursor
          innerSize={15}
          outerSize={15}
          color="255, 255 ,255"
          outerAlpha={0.4}
          innerScale={0.7}
          outerScale={5}
        />
      </div> */}
      <ScrollToTop>
        <Headermain />
        <AppRoutes />
      </ScrollToTop>
    </Router>
  );
}
